import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import SEO from '../components/seo';
import Logo from 'src/components/logo';
import BassinActivite from 'src/components/images/bassin-activite';
import BassinFace from 'src/components/images/bassin-face';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Bienvenue sur le site de l'enfant d'eau" description="L'enfant d'eau est l'association des bébés nageurs de Paris centre." mdxType="SEO" />
    <div className="mt-10 sm:mt-12 md:mt-16 lg:mt-20  mb-16">
  <div className="text-center">
    <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
      <Logo className="h-48 mx-auto" mdxType="Logo" />
      L'enfant d'eau
    </h1>
    <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
      Association à but non lucratif organisant des activités aquatiques
      pour les enfants
    </p>
  </div>
    </div>
    <h2>{`Une activité associative`}</h2>
    <p>{`Depuis 1988, l'association propose une activité de bébés nageurs pour les enfants de 5 mois à 5 ans. Toute l'année, les parents bénévoles organisent l'activité qui permet à petits et grands de s'amuser ensemble. `}<a parentName="p" {...{
        "href": "association"
      }}>{`En apprendre plus sur l'association...`}</a></p>
    <BassinFace mdxType="BassinFace" />
    <h2>{`Encadrée par des professionnels`}</h2>
    <p>{`L'association emploie des professionnels de la petite enfance et de la natation pour encadrer l'activité. Ce sont des passionnés de leur métier. `}<a parentName="p" {...{
        "href": "pedagogie"
      }}>{`Lire ce qu'ils disent de l'activité`}</a>{`.`}</p>
    <BassinActivite mdxType="BassinActivite" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      