import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default function BassinActivite() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bassin_cote.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="flex content-center justify-center my-8">
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Une séance d'activité de l'enfant d'eau avec un encadrant"
        className="shadow-lg rounded-lg w-full"
      />
    </div>
  );
}
